const lastDeploy = ` ------------------------------------------------------------------------- 
 ------------------------------------------------------------------------- 
      Welcome to cemento! Last update - 20/11/2024, 14:38:36
      Version No.  0.99.5.2.64
 ------------------------------------------------------------------------- 
 ------------------------------------------------------------------------- 
`;
const date = '20/11/2024, 14:38:36';
const version = '0.99.5.2.64';

module.exports = {
    lastDeploy,
    date,
    version,
};
